import React from 'react';

const JoinUsIntro = () => {
  return (
    <div className='joinus-intro'>
      <h1 className='joinus-intro-title'>
        <span>
          Join us at <strong>ITIO</strong>
        </span>
      </h1>
      <div className='joinus-intro-image'></div>
    </div>
  );
};

export default JoinUsIntro;
